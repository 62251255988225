<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <!-- <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>-->
          <h1>Projetos de Voluntariado</h1>
        </div>
        <div class="filter-list-agroup" v-if="false">
          <!-- NÃO ESTÁ PRONTO AINDA. NÃO REMOVER V-IF! -->
          <v-layout row wrap>
            <v-flex sm6 xs12 pr-3 pr-600-0>
              <InputSearch type="text" place-holder="Busca voluntariado" />
            </v-flex>
            <v-flex sm6 xs12 display-flex>
              <InputSelect></InputSelect>
              <v-menu offset-y transition="slide-y-transition" bottom left>
                <template v-slot:activator="{ on }">
                  <button
                    v-on="on"
                    class="menu-list-project elevation-base flex-inherit v-btn--flat v-btn--round"
                  >
                    <Icon
                      name="fal fa-layer-group icon-grouping active"
                      size="24"
                      class="mr-2"
                      colorFont="#c3c6d5"
                    />
                  </button>
                </template>
                <v-list class="options-menu-global">
                  <v-list-tile>
                    <v-list-tile-title class="group-list">
                      <Icon
                        name="fal fa-map-marker-alt"
                        size="16"
                        class="mr-2"
                        colorFont="#C3C6D5"
                      />Localidade
                      <Icon
                        name="fas fa-check-circle"
                        size="16"
                        class="ml-5 mr-0 btn-check-group-list"
                        colorFont="#C3C6D5"
                      />
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile>
                    <v-list-tile-title class="group-list active">
                      <div>
                        <Icon
                          name="fal fa-hand-receiving"
                          size="16"
                          class="mr-2"
                          colorFont="#C3C6D5"
                        />Causa
                      </div>
                      <Icon
                        name="fas fa-check-circle"
                        size="16"
                        class="ml-5 mr-0 btn-check-group-list"
                        colorFont="#C3C6D5"
                      />
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile>
                    <v-list-tile-title class="group-list">
                      <Icon name="fal fa-flag" size="16" class="mr-2" colorFont="#C3C6D5" />Status
                      <Icon
                        name="fas fa-check-circle"
                        size="16"
                        class="ml-5 mr-0 btn-check-group-list"
                        colorFont="#C3C6D5"
                      />
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile>
                    <v-list-tile-title class="group-list">
                      <Icon name="fal fa-box-usd" size="16" class="mr-2" colorFont="#C3C6D5" />Tipo de Projeto
                      <Icon
                        name="fas fa-check-circle"
                        size="16"
                        class="ml-5 mr-0 btn-check-group-list"
                        colorFont="#C3C6D5"
                      />
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile>
                    <v-list-tile-title class="group-list">
                      <Icon name="fal fa-gavel" size="16" class="mr-2" colorFont="#C3C6D5" />Lei de Incentivo
                      <Icon
                        name="fas fa-check-circle"
                        size="16"
                        class="ml-5 mr-0 btn-check-group-list"
                        colorFont="#C3C6D5"
                      />
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </v-flex>
          </v-layout>
        </div>
        <!-- boxapp body -->
        <div class="box-main">
          <div class="section projects-list">
            <v-layout justify-end w-100>
              <v-btn
                flat
                round
                color="white"
                class="btn-primary flex-inherit"
                large
                @click="newProject()"
              >
                <i class="fas fa-plus mr-2"></i> Novo Projeto de Voluntariado
              </v-btn>
            </v-layout>

            <VolunteeringItem
              :currentPhase="projectPhasesText.ProjetosEmCadastramento"
              :list="projectList"
              :service="volunteeringService"
              @refresh="refreshList"
            />
          </div>
        </div>
      </div>

      <div class="box-help-spacer"></div>
      <HelpBox :text="helpBoxText" />
    </v-layout>
  </div>
</template>

<script type="plain/text">

import FooterStep from "@/components/FooterStep.vue";
import VolunteeringItem from "@/components/VolunteeringItem.vue";
import VolunteeringService from "@/scripts/services/volunteering.service";
import InstitutionService from "@/scripts/services/institution.service";
import { Routes } from "@/scripts/models/enums/routes.enum";
import { ProjectPhasesText } from "@/scripts/models/enums/projectPhases.enum.js";
import HelpBox from "@/components/HelpBox.vue";
import { HelpBoxTextVolunteering } from "@/scripts/models/enums/helpBoxTextVolunteering.enum.js";
import Permissions from "@/scripts/models/enums/permissions.enum";
import store from "@/store.js";

export default {
  components: { HelpBox, FooterStep, VolunteeringItem },
  props: ["pageOptions"],
  data() {
    return {
      entityId: null,
      institutionId: null,
      volunteeringService: new VolunteeringService(),
      institutionService: new InstitutionService(),
      projectList: {},
      projectPhasesText: ProjectPhasesText,
      routes: Routes,
      helpBoxText: HelpBoxTextVolunteering.ProjectList,
      breadcrumbLevels: [{ text: "Início" }, { text: "Projetos de Voluntariado" }],
    };
  },
  beforeRouteEnter: (to, from, next) => {
    // to.query.institutionId &&
    // to.query.institutionId != 0
    if (to.query.entityId && to.query.entityId != 0) {
      const entityPermission = store.getters.hasPermission([Permissions.CRUDProject], to.query.entityId);
      const generalPermission = store.getters.hasPermission([Permissions.CRUDProjectAll]);
      if (!entityPermission && !generalPermission) {
        store.commit("SET_SNACKBAR_MESSAGE", "Você não tem permissão para acessar essa página.");
        next(false);
      } else {
        next();
      }
    } else {
      next({ name: Routes.app.DashboardPF });
    }
  },
  created() {
    if (this.$route.query.auc) {
      var url = `${location.protocol}//${location.host}${location.pathname}?entityId=${this.$route.query.entityId}`;
      location.href = url;
    }
    this.entityId = this.$route.query.entityId;
    this.institutionId = this.$route.query.institutionId;
    this.pageOptions.changeClass(null);
    this.$emit("changeLevels", this.breadcrumbLevels);
    this.refreshList();
  },
  methods: {
    async refreshList() {
      await this.volunteeringService.listAll(this.entityId, this.institutionId).then((data) => {
        this.projectList = data;
      })
    },
    newProject() {
      this.$router.push({
        name: Routes.app.VolunteeringStep1,
        query: { institutionId: this.$route.query.institutionId },
      });
    },
    filterByPhase(currentPhase, item) {
      return item.projectPhaseId === currentPhase.id;
    },
  },
};
</script>
